.my-caro {
  width: 100vw;
  padding: 0px 20% 0 20%;
}

@media (max-width: 1024px) {
  .my-caro {
    padding: 0px 0 0 0;
  }
}
.header {
  margin: 20px 0;
}

.description {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-weight: 400;
}

.latest-button {
  width: 90px;
  height: 25px;
  margin-left: 10px;
  background: #ab393c;
  border: none;
  color: white;
  font-size: 15px;
  position: relative;
  top: 2px;
  margin-bottom: -5px;
  z-index: 100;
  align-self: center;
}

.exit {
  text-decoration: none;
  color: grey;
  font-size: 3.5em;
  font-family: "Roboto Mono", monospace;
  height: 56px;
  width: 49px;
  transition: 300ms;
}

@media (max-width: 1024px) {
  .exit {
    font-size: 2.5em;
  }
}
@media (max-width: 500px) {
  .exit {
    font-size: 2.5em;
  }
}

.exit:hover {
  text-decoration: none;
  color: black;
  font-size: 3.7em;
}

.btn {
  font-family: "Roboto Mono", monospace;
  width: 146px;
  height: 50px;
  line-height: 32px;
  color: white;
  background-color: #8b77c0;
  border: 2px solid rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  border-radius: 0px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  box-shadow: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  text-decoration: none;
}
.btn:hover {
  background-color: #513697;
  color: white;
  border: 2px solid white;
}

.project-description {
  display: flex;
  flex-direction: column;
  padding: 0 20%;
  align-items: center;
}

@media (max-width: 500px) {
  .project-description {
    padding: 0 20px;
  }
}

.btn-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.my-container {
  /* width: 100vw; */
  margin-bottom: 25px;
  /* height: 100vh; */
  padding-top: 300px 0;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}

latest-feed {
  height: 0px;
}

@media (max-width: 480px) {
  .latest-feed {
    height: 240px;
  }
}

@media (max-width: 1200px) {
  .latest-feed {
    height: 300px;
  }
}

.loading-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
